import { IonButton, IonCol, IonContent, IonIcon, IonRow, IonSpinner, useIonToast } from '@ionic/react';
import Header from '../components/Header/Header';
import { onScroll } from '../services/sticky-menu.service';
import Footer from '../components/Footer';
import { apiBaseUrl} from '../configs/app';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import Invoice from '../types/shop/invoice.type';
import { print } from 'ionicons/icons';
import onPrint from '../services/print.service';
import InvoiceBody from '../components/InvoiceBody';

const BDT = new Intl.NumberFormat('en-IN');
const GuestUserInvoice: React.FC = () => {
    const { encryptedInvoiceID } = useParams() as any
    const [isLoading, setIsLoading] = useState(true)
    const [invoice, setInvoice] = useState<Invoice | null>(null)
    const [presentToast] = useIonToast();
    useEffect(() => {
        setIsLoading(true)
        fetch(apiBaseUrl + "guest/invoice/" + encryptedInvoiceID).then(response => {
            if (response.status == 200) {
                return response.json()
            } else {
                throw response.json()
            }
        }).then((invoiceNew: Invoice) => {
            console.clear()
            console.log("Invoice Data", invoiceNew)
            setInvoice(invoiceNew)
        }).catch(errPromise => {
            errPromise.then((errResponse: any) => {
                console.error(errResponse)
                presentToast({
                    message: errResponse.message ? errResponse.message : "Something Went Wrong",
                    duration: 3000,
                    cssClass: 'custom-toast',
                    animated: true,
                    color: "danger",
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ],
                })
            })
        }).finally(() => {
            setIsLoading(false);
        })
    }, [encryptedInvoiceID])
    return (
        <IonContent scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                    <div style={{ textAlign: 'center' }} className='print-hidden'>
                        {isLoading ?
                            <IonSpinner style={{ marginTop: 100, marginBottom: 100 }} /> :
                            <>
                                {invoice ? <>
                                    <h1 style={{ marginTop: 20, marginBottom: 20 }}>
                                        Guest User Invoice
                                    </h1>
                                    <p>Please, bookmark this page for tracking your order. Don't forget to print this page. You may not find this page for future reference.</p>
                                    <IonButton onClick={onPrint}>
                                        <IonIcon icon={print} slot='start' />
                                        Print
                                    </IonButton>
                                </> : <>
                                    <h1 style={{ marginTop: 100, marginBottom: 100 }}>
                                        Guest User Invoice Not Found
                                    </h1>
                                </>}
                            </>}
                    </div>
                    {!isLoading && invoice && <div className='print-section'>
                        <InvoiceBody data={invoice} type="Customer"/>
                    </div>}
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default GuestUserInvoice;
