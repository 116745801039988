import { Redirect, Route } from 'react-router-dom';
import {
    IonRouterOutlet
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { appSubDir } from '../configs/app';
import Home from '../pages/Home';
import Contact from '../pages/Contact';
import About from '../pages/About';
import FlashSale from '../pages/products/FlashSale';
import Shop from '../pages/products/Shop';
import Category from '../pages/products/Category';
import NotFound from '../pages/NotFound';
import SubCategory from '../pages/products/SubCategory';
import Product from '../pages/products/Product';
import Brand from '../pages/products/Brand';
import NewArrival from '../pages/products/NewArrival';
import PaymentSuccess from '../pages/payments/PaymentSuccess';
import PaymentVerify from '../pages/payments/PaymentVerify';
import PaymentCancelled from '../pages/payments/PaymentCancelled';
import PaymentFailed from '../pages/payments/PaymentFailed';
import GuestUserInvoice from '../pages/GuestUserInvoice';
import OrderPlaced from '../pages/payments/OrderPlaced';
import Search from '../pages/products/Search';
import ResellerRegistration from '../pages/ResellerRegistration';
import PrivacyPolicy from '../pages/kegal/PrivacyPolicy';
import TermsContitions from '../pages/kegal/TermsContitions';


const PublicRoutes: React.FC = () => (
    <IonReactRouter>
        <IonRouterOutlet>
            <Route exact path={appSubDir}>
                <Home />
            </Route>
            <Route exact path={appSubDir + "about"}>
                <About />
            </Route>
            <Route exact path={appSubDir + "contact"}>
                <Contact />
            </Route>
            <Route exact path={appSubDir + "shop"}>
                <Shop />
            </Route>
            <Route exact path={appSubDir + "flash-sale"}>
                <FlashSale />
            </Route>
            <Route exact path={appSubDir + "new-arrival"}>
                <NewArrival />
            </Route>
            <Route exact path={appSubDir + "product/:slug/item-:ID"}>
                <Product />
            </Route>
            <Route exact path={appSubDir + "search/:searchKey"}>
                <Search />
            </Route>
            <Route exact path={appSubDir + "categories/:catID"}>
                <Category />
            </Route>
            <Route exact path={appSubDir + "brands/:brandID"}>
                <Brand />
            </Route>
            <Route exact path={appSubDir + "categories/:catID/sub-categories/:subCatID"}>
                <SubCategory />
            </Route>
            <Route exact path={appSubDir + "payment-verify/:valID"}>
                <PaymentVerify />
            </Route>
            <Route exact path={appSubDir + "order-placed"}>
                <OrderPlaced />
            </Route>
            <Route exact path={appSubDir + "payment/success"}>
                <PaymentSuccess />
            </Route>
            <Route exact path={appSubDir + "payment/failed"}>
                <PaymentFailed />
            </Route>
            <Route exact path={appSubDir + "payment/cancelled"}>
                <PaymentCancelled />
            </Route>
            <Route exact path={appSubDir + "guest-invoice/:encryptedInvoiceID"}>
                <GuestUserInvoice />
            </Route>
            <Route exact path={appSubDir + "reseller-registration"}>
                <ResellerRegistration />
            </Route>
            <Route exact path={appSubDir + "privacy-policy"}>
                <PrivacyPolicy />
            </Route>
            <Route exact path={appSubDir + "terms-and-conditions"}>
                <TermsContitions />
            </Route>
            <Route>
                <NotFound />
            </Route>
        </IonRouterOutlet>
    </IonReactRouter>
);

export default PublicRoutes;
