import { apiBaseUrl } from "../../configs/app";
import { ShippingCost } from "../../types/shop/cart.type";
import CryptoService from "../crypto.services";
const localStorageKey = CryptoService.hash('shipping-costs')
export function getShippingCosts() {
    const localData = window.localStorage.getItem(localStorageKey)
    return (localData ? JSON.parse(CryptoService.decrypt(localData)) as ShippingCost[] : [] as ShippingCost[])
}
export function getShippingCost(id: number) {
    const items = getShippingCosts()
    return items.find((item) => (item.id === id))
}
export function fetchShippingCosts() {
    const url = apiBaseUrl + `shipping-costs`
    return fetch(url).then((res) => res.json())
        .then((res: ShippingCost[]) => {
            if (res.length > 0) {
                window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(res)))
                return res
            } else {
                return [] as ShippingCost[]
            }
        })
}
